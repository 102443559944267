<template>
    <div>
      <strong>Perfil: {{ adminisrador }}</strong><br>
      <table>
        <tr v-if="list.length == 0">
          <td colspan='3' style="text-align-last: center;"><strong>No hay datos</strong></td>
        </tr>
        <tr v-if="list.length > 0">
          <th>Nombre del Rol</th>
          <th>Estado</th>
          <th>Fecha Asignación</th>
        </tr>
        <tr v-for="(item, index) in list" :key="index">
          <td>{{ item.roleName }}</td>
          <td>Habilitado</td>
          <td>{{ formatDate(item.edited) }}</td>
        </tr>
      </table>
    </div>
  </template>
  
  <script>
  import { dateFormatting } from "../../../assets/config/utilities";
  import axios from "axios";
  import { config } from "../../../assets/config/jsonconfig.json";
  const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
  import { getToken } from "../../../assets/config/getToken.js";

  export default {
    name: "DelegationCardDetail",
    props: [
      'props',
      'ciamId',
      'represent'
    ],
    async mounted() {
      this.token = await getToken(config.DOMINIO);
      this.datosDelegado = await this.cargarDta();
      console.log(this.datosDelegado);
      if (this.datosDelegado.roleList) {
        this.list = this.datosDelegado.roleList
        this.list.forEach(item => {
          if (item.roleName == 'Crear Delegados') {
            this.adminisrador = 'Super Administrador'
          }
        });
      }else{
        console.log('error en la carga de datos');
      }
    },
    data() {
      return {
        datosDelegado: [],
        adminisrador: 'Delegado',
        list: [],
        token: null
      }
    },
    methods: {
      formatDate(date) {
        return dateFormatting(date);
      },
      async cargarDta() {
        let url = urlBaseTiposdeDocumento + '/control_delegados_roles/'
        if ('delegation' == this.represent) {
          url += this.ciamId +`/`+ this.props.row.ciamId
        } else {
          url += this.props.row.ciamId +`/`+ this.ciamId
        }
        
        return await axios({
          method: 'get',
          url: url,
          headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
          }
        }).then((res) => {
          return res.data;
        }).catch((error) => {
            return error
        })
      }
    }
  }
  </script>